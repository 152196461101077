
.align-logo{
    margin-top: 20%;
    margin-left: 3%;
}


.font-triluxo{
    font-family: 'Signika';
    font-size: 80px;
    color: rgb(255, 127, 79);
    text-shadow: 0 0 3px #000000;
    letter-spacing: 5px;
}

.custom-dialog{
    max-width: auto !important;
    top: 23%;
}
