$padding: 12px;
$radius: 6px;
$color: #333;
$color-light: white;
$primary-color: #3498db;
$primary-color-light: lighten($primary-color, 40%);
$bg-color-light: #DDD;

$app-width: 800px;
$app-height: 450px;
$left-menu-width: 200px;

$message-min-width: 75px;
$message-max-width: ($app-width - $left-menu-width) / 1.5;
$message-line-height: 20px;


* {
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  outline: none;
}

.app {
  background-color: $color-light;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  border-radius: $radius;
  overflow: hidden;
  

  
  .contact-list {
 
    background-color: $color-light;
    width: $left-menu-width;
    overflow: auto;

    .title {
      padding: $padding; margin: 0;
      padding-bottom: 0;
      font-size: 150%;
    }
    
    ul {
      list-style: none;
      margin: 0; padding: $padding;
      
      li {
        margin: $padding / 1.5 0;
        padding: $padding / 1.5 $padding;
        position: relative;
        cursor: pointer;
        transition: all .2s ease-out;
        border: 2px solid gainsboro;
        
        &.active, &:hover {
          background-color: $primary-color-light;
          color: $primary-color;
          border-radius: $radius;
        }
      }
    }
  }
  
  .messages {
  
    background-color: $color-light;
    border-radius: $radius;
    overflow: hidden;
    
    flex: 1 1 auto;
    
    display: flex;
    flex-direction: column;
    
    .messages-history {
      overflow: auto;
      
      flex: 1 1 auto;
      
      display: flex;
      flex-direction: column-reverse;
      align-items: end;
      
      .message {
        width: 100%;
        padding: $padding;
        padding-top: 0;
        
        &:last-child {
          padding-top: $padding;
        }
        
        &.me {
          text-align: right;
          
          .message-body {
            color: $color-light;
            background-color: $primary-color;
          }
        }
        
        .message-body {
          background-color: $bg-color-light;
          color: $color;
          font-weight: 400;
          display: inline-block;
          padding: $padding / 1.5 $padding;
          border-radius: $radius;
          max-width: $message-max-width;
          min-width: $message-min-width;
          line-height: $message-line-height;
          text-align: initial;
        }
      }
    }
    
    .messages-inputs {
      display: flex;
      
      input[type="text"] {
        background-color: transparent;
        border: none;
      
        resize: vertical;
        
        flex: 1 1 auto;
      }
      
      button {
        padding: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        
        &:hover {
          i {
            color: $primary-color;
          }
        }
        
        i {
          vertical-align: middle;
          transition: all .2s ease-out;
        }
      }
    }
  }
}