body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #ecf0f1;
  }
  
  .menu {
    background: #34495e;
    height: 100vh;
    width: 250px;
    opacity:0; 
    position: absolute;
    visibility: hidden;
    transition: all 0.25s ease;
    transform: translateX(-50%);
  
    #link {
      color: #fff;
      text-decoration: none;
      display: block;
      margin-left: 50px;
      padding: 20px;
    }
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        font-size: 16px;
        border-bottom: 1px solid #fff;
        transition: all 0.25s ease;
        animation: fadeInRight .25s ease forwards;
  
        &:hover {
          opacity: 0.8;
          transition: all 0.25s ease;
          background: #000;
          cursor: pointer;
        }
      }
    }
  }
  
  .active {
    opacity:1;
    visibility: visible;
    transition: all 0.25s ease;
    transform: translateX(0);
  }
  

  .shortcuts{
    background: #34495e;
    height: 100vh;
    width: 60px;
    position: absolute;



    #link {
      color: #fff;
      text-decoration: none;
      display: block;
      padding: 20px;
    }
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        border-bottom: 1px solid #fff;
  
        &:hover {
          opacity: 0.8;
          transition: all 0.25s ease;
          background: #000;
          cursor: pointer;
        }
      }
    }
  
   
  }

  .activeRoute{
    background-color: rgb(255, 127, 79);
  }